<template>
  <div>
    <v-container :class="`mt-10 ${$vuetify.breakpoint.xs?'':''}`">
      <h1>Start a new Challenge</h1>
      <p>Select one of the templates below to get started.</p>

      <v-alert type="info" outlined><strong>Beta</strong>: Challenge Templates are new. Questions or suggestions? <router-link to="/contact">Contact Us</router-link>.</v-alert>
      <v-alert v-if="mapTemplate" type="info">Only challenges compatible with the selected <strong>{{mapTemplate.name}}</strong> journey are displayed.</v-alert>
    </v-container>

    
    <EventTemplateGrid @click="openTemplate" :filter="filter" />
    
    <multi-step-dialog ref="detailsDialog" title="Template Details" confirm-label="Select" single-step hide-header @confirm="startTemplate">
      <v-stepper-items v-if="template">
      <v-stepper-content :step="1" class="pa-0">
        <v-img
                v-if="template.img"
                :src="template.img"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="250px"
              > 
                <v-card-title class="mb-16 display-2">{{template.name | trim(50)}}</v-card-title>
                <!-- <v-card-text class="mb-12">{{template.summary}}</v-card-text> -->
        </v-img>
        <v-card-title v-if="!template.img" class="mb-4 display-2">{{template.name | trim(50)}}</v-card-title>
        <v-card :class="{'mx-4 pa-4': true, 'mt-n16 mb-4': template.img, 'my-2': !template.img}">
        <vue-markdown class="markdown mb-4" :html="false" :source="template.description" />
        <v-alert v-if="mapTemplate" type="info" tile>The <strong>{{mapTemplate.name}}</strong> journey will be applied during configuration.</v-alert>
        <div v-if="false && template.setup_tips">
          <h4>Set-up tips</h4>
          <vue-markdown class="markdown mb-4" :html="false" :source="template.setup_tips" />
        </div>
        <div v-if="template.rules">
          <h4>How it works</h4>
          <vue-markdown class="markdown mb-4" :html="false" :source="template.rules" />
        </div>
        <div v-if="template.parameters && template.parameters.length">
          <h4>Additional options for this event</h4>
          <ul>
            <li v-for="(item, idx) in template.parameters" :key="idx">{{ item.label }}</li>
            <li v-for="(item, idx) in (template.decorators||[]).filter(x => !x.if_parameter)" :key="`${idx}_decorator`">{{ $helpers.displayText(siteData.decorator_types, item.type) }}</li>
          </ul>
        </div>
        </v-card>
      </v-stepper-content>
      </v-stepper-items>
    </multi-step-dialog>

  </div>
</template>


<script>
import assetsService from "@/services/assetsService";
import eventManagerService from "@/services/eventManagerService";
import navData from '@/data/nav.json'
import siteData from '@/data/site.json'
import MultiStepDialog from '@/components/generic/MultiStepDialog.vue';
import ToggleButtonInput from "@/components/ToggleButtonInput";
import VueMarkdown from '@/components/VueMarkdown.vue'
import EventTemplateGrid from '@/components/templates/EventTemplateGrid.vue'
import EventTemplateCard from '@/components/templates/EventTemplateCard.vue'
import MapTemplateCard from '@/components/templates/MapTemplateCard.vue'

export default {
  name: "Contact",
  components: {
    MultiStepDialog,
    VueMarkdown,
    ToggleButtonInput,
    EventTemplateGrid,
    MapTemplateCard,
    EventTemplateCard,
  },
  props: {
  },
  data: function() {
    return {
      navData: navData,
      siteData: siteData,
      filter: null,
      templates: null,
      template: null,
      mapTemplates: null,
      mapTemplate: null,
      mode: null,
      tags: [],
      query: null,
    };
  },
  created() {
  },
  async mounted() {
    this.templates = (await assetsService.getEventTemplates()).data.data;
    this.mapTemplates = (await assetsService.getMapTemplates()).data.data;
    
    if (this.$route.query.tags) {
      this.filter = { tags: this.$route.query.tags.split(',') };
    }
    if (this.$route.query.mapTemplateId) {
      this.mapTemplate = this.mapTemplates.find(x => x.id == this.$route.query.mapTemplateId);
    }
  },
  methods: {
   openTemplate(item) {
    this.template = item;
    this.$refs.detailsDialog.open();
   },
   async startTemplate() {
    if (this.$route.query.eventId) {
      const response = (await eventManagerService.connectEventTemplate(this.$route.query.eventId, this.template.id)).data;
      this.$helpers.toastResponse(this, response, 'The template has been selected.');
      if (response.status == 'OK') {
        this.$router.push({name: 'eventmanagerConfigTemplate', params: {id: this.$route.query.eventId }});
      }
    }
    else {
      this.$router.push({name: 'eventmanagerCreate', query: {templateId: this.template.id, groupId: this.$route.query.groupId, mapTemplateId: this.$route.query.mapTemplateId, wellbeingPackId: this.$route.query.wellbeingPackId }});
    }
   },
   async clearFilters() {
    this.query = null;
    this.mode = null;
    this.tags = [];
    this.applyFilters();
   },
   async applyFilters() {
      let filters = [...this.tags, this.mode];
      //console.log('/// filter, tags', filters);
      this.templates = (await assetsService.getEventTemplates(filters, this.query)).data.data;
   },
  },
  watch: {
  }
};
</script>

<style lang="scss" scoped>
  .indent { margin: 0 20%; }

  .features {
    i { background-color: #F4652422; border-radius: 50%; padding: 20px; margin-top: 20px; }
    .plus {
      margin: 0 20px; line-height: 40px;
      color: #F46524;
    }
    h4 { margin-top: 8px; }
  }
</style>